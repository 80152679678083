<template>
	<app-layout v-loading="pageLoading" size="none" class="staff staffcorehuman" :needHeader="false">
		<el-tabs class="infoTab" v-model="activeName" style="padding: 15px 0" @tab-click="firstchangeTab">
			<el-tab-pane v-for="(tab, i) in tabOptions" :label="tab.label" :key="i" :name="tab.name"></el-tab-pane>
		</el-tabs>
		<el-tabs v-model="navActive" class="cardtab app-container" @tab-click="changeTab">
			<el-tab-pane name="3" :label="`待审批(${numbers[0]})`"></el-tab-pane>
			<el-tab-pane name="1" :label="`审批通过(${numbers[1]})`"></el-tab-pane>
			<el-tab-pane name="0" :label="`审批失败(${numbers[2]})`"></el-tab-pane>
		</el-tabs>
		<el-form :model="searchForm" ref="searchForm" style="padding: 0 20px; margin: 0 15px" inline>
			<el-form-item label="部门">
				<el-input v-model="searchForm.orgName" placeholder="请输入部门名称" size="small"></el-input>
			</el-form-item>
			<el-form-item label="姓名">
				<el-input v-model="searchForm.name" placeholder="请输入姓名" size="small"></el-input>
			</el-form-item>
			<el-form-item label="核心人力等级">
				<el-select v-model="searchForm.coreLevel" placeholder="请选择核心人力等级" size="small" clearable>
					<el-option v-for="(item, i) in levels" :key="i" :label="item.levelName" :value="item.levelName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生成时间">
				<el-date-picker
					v-model="searchForm.createTimeStart"
					size="small"
					:picker-options="pickerOptionsStart"
					value-format="yyyy-MM-dd HH:mm:ss"
					type="datetime"
					default-time="00:00:00"
					placeholder="选择日期时间"
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.createTimeEnd"
					size="small"
					:picker-options="pickerOptionsStart"
					value-format="yyyy-MM-dd HH:mm:ss"
					type="datetime"
					default-time="23:59:59"
					placeholder="选择日期时间"
				></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getList" size="small">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" stripe header-row-class-name="tableClass" style="width: 96%; margin: 0 auto">
			<el-table-column label="序号" type="index" align="center"></el-table-column>
			<el-table-column v-for="col in columns" :prop="col.id" align="center" :key="col.id" :label="col.label" :width="col.width">
			</el-table-column>
			<el-table-column label="操作" align="center">
				<template slot-scope="{ row }">
					<el-button type="text" @click="review(row)" v-if="Number(navActive) == 3">审批</el-button>
					<el-button type="text" @click="Detail(row, true)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="float: right; margin: 20px 30px 0 0"
			@size-change="sizeChange"
			@current-change="currentChange"
			:current-page="table.pageNo"
			:page-sizes="[20, 40, 80, 100]"
			:page-size="table.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="table.totalNum"
		>
		</el-pagination>
		<el-dialog :title="`${currentRow.name}`" :visible.sync="dialogVisible" class="loser" center width="45%">
			<el-form ref="form2" :rules="rules2" :model="form2" label-width="80px">
				<el-form-item prop="approvalResult" label="状态">
					<el-radio-group v-model="form2.approvalResult">
						<el-radio :label="1">通过</el-radio>
						<el-radio :label="0">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item prop="approvalOpinion" label="原因">
					<el-input type="textarea" maxlength="100" minlength="1" v-model="form2.approvalOpinion" :autosize="{ minRows: 4, maxRows: 4 }" />
				</el-form-item>
				<el-form-item label="附件">
					<el-upload
						action="#"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.zip,.rar,.PDF,.DOC,.DOCX,.XLS,.XLSX,.ZIP,.RAR"
						:on-preview="handlePreview"
						v-loading="contrachLoading"
						:http-request="onUploadChange"
						:on-remove="handleRemove"
						multiple
						:limit="5"
						:on-exceed="handleExceed"
						:file-list="form2.annexContent"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" style="line-height: 20px" class="el-upload__tip">
							文件格式为JPG/JPEG/DOC/DOCX/XLS/XLSX/PDF/ZIP/RAR，大小在5MB内，数量在5个之内
						</div>
					</el-upload>
				</el-form-item>
				<el-row style="text-align: center; margin: 30px 0 10px">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" :loading="isAxios" @click="handleApproval">确 定</el-button>
				</el-row>
			</el-form>
		</el-dialog>
	</app-layout>
</template>

<script>
import { approvalHumanstaff, coreConfiglList, coreApprovalSubmit, approvalListCount } from '../../api/corehuman';
import axios from 'axios';
export default {
	components: {},
	data() {
		return {
			activeName: '1',
			navActive: '3',
			isAxios: false,
			pageLoading: false,
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			pickerOptionsStart: {
				disabledDate(time) {
					return time.getTime() > new Date(new Date().toLocaleDateString()).getTime();
				}
			},
			dialogVisible: false,
			contrachLoading: false,
			tabOptions: [
				{ name: '1', label: '核心人力申报' },
				{ name: '2', label: '核心人力复查' },
				{ name: '3', label: '核心人力调整' }
			],
			numbers: [0, 0, 0],
			levels: [],
			searchForm: {
				orgName: '',
				coreLevel: '',
				name: '',
				createTimeStart: '',
				createTimeEnd: ''
			},
			tableData: [{}],
			columns: [
				{ id: 'name', label: '姓名' },
				{ id: 'phoneNumber', label: '手机号码' },
				{ id: 'jobNumber', label: '工号' },
				{ id: 'orgName', label: '部门' },
				{ id: 'officeName', label: '职位' },
				{ id: 'latelyPerformance', label: '最近绩效' },
				{ id: 'coreLevel', label: '申请等级' },
				{ id: 'entryTime', label: '入职时间' },
				{ id: 'createTime', label: '生成时间' }
			],
			form2: {
				coreManpowerApprovalId: '',
				approvalResult: '',
				approvalOpinion: '',
				annexContent: []
			},
			rules2: {
				approvalResult: [{ required: true, message: '请选择状态', trigger: 'change' }],
				approvalOpinion: [{ required: true, message: '请输入原因', trigger: 'blur' }]
			},
			currentRow: { name: '核心人力' },
			isinit: true,
			table: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			}
		};
	},
	beforeRouteLeave(form, to, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				searchForm: this.searchForm,
				activeName: this.activeName,
				navActive: this.navActive
			})
		);
		next();
	},
	mounted() {
		const params = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (params && params.activeName) {
			this.activeName = params.activeName;
		}
		if (params && params.searchForm) {
			this.searchForm = params.searchForm;
		}
		if (params && params.navActive) {
			this.navActive = params.navActive;
		}
		coreConfiglList({}).then((res) => {
			if (res._responseStatusCode == 0) {
				this.levels = res.configListDtos;
			}
		});
		this.getNumber();
		this.getList();
		this.isinit = false;
	},
	methods: {
		firstchangeTab() {
			if (!this.isinit) {
				this.navActive = '3';
			}
			this.getNumber();
			this.getList();
		},
		changeTab() {
			this.getNumber();
			this.getList();
		},
		handleExceed(files, fileList) {
			if (files.length > 5 || fileList.length === 5 || files.length + fileList.length > 5) {
				this.$message({
					message: '上传文件数量不能超过五个',
					type: 'warning'
				});
			}
		},
		getNumber() {
			approvalListCount({ approvalType: this.activeName }).then((res) => {
				if (res._responseStatusCode == 0) {
					this.numbers = [0, 0, 0];
					this.numbers[0] = res.waitCount;
					this.numbers[1] = res.passCount;
					this.numbers[2] = res.failCount;
				}
			});
		},
		review(row) {
			this.form2.coreManpowerApprovalId = row.coreManpowerApprovalId;
			this.form2.annexContent = [];
			this.$nextTick(() => {
				this.$refs.form2.clearValidate();
				this.$refs.form2.resetFields();
			});
			this.dialogVisible = true;
		},
		_getValue(eve) {
			this.searchForm.orgId = eve ? eve.id : '';
		},
		Detail(row, isinfo) {
			// eslint-disable-next-line default-case
			switch (this.activeName) {
				case '1':
					this.$router.push({
						name: 'applyInfo',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, showApproval: true }
					});
					break;
				case '2':
					this.$router.push({
						name: 'checkInfo',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, showApproval: true }
					});
					break;
				case '3':
					this.$router.push({
						name: 'corehumanAdjust',
						params: { coreManpowerApprovalId: row.coreManpowerApprovalId },
						query: { isinfo, showApproval: true }
					});
					break;
			}
		},
		getList() {
			const params = {
				...this.searchForm,
				approvalType: this.activeName,
				approvalResult: this.navActive,
				pageNo: this.table.pageNo,
				pageSize: this.table.pageSize
			};

			this.pageLoading = true;
			approvalHumanstaff(params).then((res) => {
				if (res._responseStatusCode == 0) {
					this.tableData = res.list;
					this.table.totalNum = res.totalNum;
					this.pageLoading = false;
				}
			});
		},
		handleApproval() {
			this.$refs['form2'].validate((valid) => {
				if (valid) {
					this.isAxios = true;
					coreApprovalSubmit({ ...this.form2, annexContent: JSON.stringify(this.form2.annexContent) }).then((res) => {
						if (res._responseStatusCode == 0) {
							this.getNumber();
							this.getList();
							this.$message.success('审批成功');
							this.isAxios = false;
							this.dialogVisible = false;
						}
					});
				}
			});
		},
		onUploadChange(event) {
			const isLt5M = event.file.size / 1024 / 1024 < 5;

			if (!isLt5M) {
				this.$alert('大小不得超过5M');
				return false;
			}
			const fileSize = this.renderSize(event.file.size);

			const filename = event.file.name;

			const formData = new FormData();

			formData.append('imgFile', event.file);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';

			this.contrachLoading = true;
			axios
				.post('/v1/common/upload/file', formData, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					this.form2.annexContent.push({ name: filename, size: fileSize, url: res.httpUrl });
					this.contrachLoading = false;
				});
		},
		handlePreview() {},
		handleRemove() {},
		// 文件大小格式化转换
		renderSize(value) {
			// eslint-disable-next-line no-eq-null
			if (value == null || value == '') {
				return '0 Bytes';
			}
			// eslint-disable-next-line no-array-constructor
			const unitArr = new Array('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB');

			let index = 0;

			const srcsize = parseFloat(value);

			index = Math.floor(Math.log(srcsize) / Math.log(1024));
			let size = srcsize / Math.pow(1024, index);

			size = size.toFixed(2); // 保留的小数位数
			return size + unitArr[index];
		},
		sizeChange(val) {
			this.table.pageSize = val;
			this.getList();
		},
		currentChange(val) {
			this.table.pageNo = val;
			this.getList();
		}
	}
};
</script>

<style lang="scss">
.staffcorehuman {
	.app-container {
		padding: 0;
	}
	label {
		font-weight: normal;
	}
	.el-dialog__body {
		padding-top: 0px;
	}
	.el-upload-list {
		.el-upload-list__item {
			width: 50%;
			padding: 5px;
			background-color: #f4f4f5;
			label i,
			.el-icon-close {
				transform: translate(-5px, 5px);
			}
		}
		.el-upload-list__item:hover {
			background-color: #f5f7fa;
		}
	}
	.el-input__suffix {
		height: 32px;
	}
	.cardtab {
		padding: 0 20px;
		margin: 0 15px;
		.el-tabs__header {
			border-bottom: none;
		}
		.el-tabs__item {
			// border-bottom: 1px solid #e4e7ed !important;
		}
	}
	.tableClass th {
		background-color: #f0f8ff;
		color: #484848;
	}
}
</style>
